// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBWQ0J05Z6CYDmhXJv-C_OKnzgUAcGg1cQ",
  authDomain: "redirectme-rgar.firebaseapp.com",
  projectId: "redirectme-rgar",
  storageBucket: "redirectme-rgar.appspot.com",
  messagingSenderId: "832804925664",
  appId: "1:832804925664:web:56b3bdb44c4c44c6a62085",
  measurementId: "G-G5XM4JHFMT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export {app};
export {analytics};
export {logEvent};
export {firestore}