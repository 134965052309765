import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';

const QRPage = () => {
  const { qr_id } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    // Define una función asincrónica para recuperar el documento
    const fetchDocument = async () => {
      try {
        // Referencia al documento que quieres recuperar
        const docRef = doc(firestore, "qrlink", qr_id);
        
        // Recupera el documento
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Si el documento existe, actualiza el estado con los datos del documento
          setDocumentData(docSnap.data());
        } else {
          console.log("El documento no existe");
        }
      } catch (error) {
        console.error("Error recuperando el documento:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [qr_id]);

  useEffect(() => {
    let timer;
    if (documentData && counter > 0) {
      timer = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
    } else if (counter === 0 && documentData && documentData.url) {
      window.location.href = documentData.url; // Redirigir a la URL
    }
    return () => clearInterval(timer);
  }, [documentData, counter]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <h1>QR ID: {qr_id}</h1>
    {documentData ? (
      <div>
        <h2>Datos del documento:</h2>
        <pre>{JSON.stringify(documentData, null, 2)}</pre>
        <div>Redirigiendo en {counter} segundos...</div>
        <script>
          window.location
        </script>
      </div>
    ) : (
      <div>No se encontró el documento.</div>
    )}
    </div>
  );
};

export default QRPage;
